.App {
    margin-bottom: 10vh;
}

.Default-Background {
    background: url('./assets/images/background.jpg') no-repeat center center fixed;
    background-size: cover;
}

.Finch-Background {
    background: url('./assets/images/finch-bg.jpg') no-repeat center center fixed;
    background-size: cover;
}