/* ERTC */
.ertc-wrapper {
    font-family: 'Roboto',sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #1D243C;
}

.ertc-container {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
}

.ertc-header {
    text-align: center;
    max-width: 615px;
    margin: 8% auto;
    margin-bottom: 10px;
}

.ertc-header > h1, p {
    font-family: 'Roboto',sans-serif;
}

.ertc-wrapper h1 {
    font-size: 3vh;
    line-height: 1.12;
    font-weight: bold;
    margin: 0 0 16px;
}

.ertc-header p {
    font-size: 14px;
    margin: 0;
    line-height: 1.6875;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
    margin-bottom: 16px;
}

.ertc-form-accordion-body p, span {
    font-family: 'Roboto',sans-serif;
    font-weight: 500;
}

.ertc-form-general-label, .ertc-form-general-label p {
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938rem;
    color: "#192C57";
}