.hero-bg {
  background: url("../../../public/assets/media/images/qualify/bg-hero.jpg")
    no-repeat center center;
  @apply text-white pt-[27%] bg-[center_top_23vw] md:mb-[-400px] lg:mb-[-100px] md:bg-top bg-[length:150vw] md:bg-contain lg:bg-cover;
}

.hero-subtext {
  font-family: Pragmatica, sans-serif;
  @apply text-[5vw] font-normal;
}

.text {
  font-family: Pragmatica, sans-serif;
}

.text-btn {
  font-family: "Inter", Sans-serif;
}

.pin-spacer {
  background-color: #2f1f3c;
}

.bg-section {
  background: url("../../../public/assets/media/images/qualify/Group.jpg")
    no-repeat center center;
}

.bg-footer {
  background: url("../../../public/assets/media/images/qualify/footer.webp")
    no-repeat center center;
  @apply bg-cover;
}

.Experts-Background {
  background: url("../../assets/images/ERTCExpertsBg.jpeg") no-repeat center
    center;
  background-size: cover;
  box-shadow: inset 0 0 0 500px rgba(32, 53, 68, 0.55);
}

.Fund-Background {
  background: url("../../assets/images/ERTCFundBg.jpeg") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 0 500px rgba(32, 53, 68, 0.7);
}

.check-list {
  list-style-type: "\2714";
}

.check-list li {
  padding-left: 0.5em;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.accordion-details {
  display: grid;
  row-gap: 2rem;
  text-align: left;
  padding-bottom: 1.5em;
}

.accordion-details p {
  color: #363636;
  font-size: 0.9em;
  font-weight: 300;
}

.flip-card {
  background-color: transparent;
  height: 20rem;
  perspective: 1000px;
  text-align: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front {
  background-color: #203444;
  row-gap: 1rem;
}

.flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  align-content: center;
  border-radius: 4rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 2rem 1rem 2rem 1rem;
}

.flip-card-back {
  background-color: white;
  transform: rotateX(180deg);
  border-color: #203444;
  border-width: 2px;
  row-gap: 2rem;
}

.flip-card-back p {
  font-size: 0.7rem;
}

@media only screen and (max-width: 600px) {
  .hero-bg {
    padding-top: 70%;
  }
}

@media screen and (min-width: 640px) {
  .flip-card-front,
  .flip-card-back {
    padding: 5rem 3.5rem 5rem 3.5rem;
  }

  .flip-card-back p {
    font-size: 1.1rem;
  }

  .accordion-details p {
    font-size: 1.1rem;
  }
}
