.prismjs-json-editor {
  @import "~prism-themes/themes/prism-material-dark";

  background-color: #282e38;
  color: white;

  pre {
    width: 100%;
    display: block !important;
  }
}
